<template>
  <div class="search">
    <div class="top">
      <div class="content">
        <div class="logo">
          <img class="logo-img" src="/logo-full.png" alt="logo-img" />
        </div>
        <div class="search-area">
          <input
            v-model="searchParams.search_text"
            class="search-input"
            @keypress.enter="refreshList(searchParams)"
            placeholder="请输入谱名、堂号、始祖、简介等"
          />
          <div class="search-btn" @click="refreshList(searchParams)">
            <span class="search-text">
              搜索
            </span>
          </div>
          <div class="exact-search-btn" @click="showSearch">
            <span class="exact-search-text">
              精确搜索
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="result-content">
      <div class="content-inner">
        <div v-if="searchParams.search_text" class="search-title">
          <div class="line"></div>
          <div class="wrapper">
            <span class="text">
              {{ searchParams.search_text }}
            </span>
          </div>
        </div>
        <div class="type-tabs">
          <div
            class="tab"
            :class="{ active: activeTab === TYPES.all }"
            @click="changeType(TYPES.all)"
          >
            全部
          </div>
          <div
            class="tab"
            :class="{ active: activeTab === TYPES.free }"
            @click="changeType(TYPES.free)"
          >
            免费
          </div>
          <div
            class="tab"
            :class="{ active: activeTab === TYPES.charge }"
            @click="changeType(TYPES.charge)"
          >
            收费
          </div>
        </div>
        <div class="result-list">
          <div
            v-for="item in resultList"
            class="result-item"
            :key="item.id"
            @click="navigateToDetail(item.id, item.price)"
          >
            <div class="item-main">
              <div v-if="item.price == 0" class="tag-container">
                免费
              </div>
              <div v-else class="tag-container charge">
                收费
              </div>
              <div v-if="readed.has(item.id)" class="read-tag">
                已查看
              </div>
              <img
                class="item-cover"
                :src="item.cover_image"
                alt="item-cover"
              />
              <div class="item-content">
                <div class="item-content-inner">
                  <div class="item-info">
                    <span class="label">
                      题名：
                    </span>
                    <span class="value">
                      {{ item.title }}（{{ item.juanming }}）
                    </span>
                  </div>
                  <div class="item-info">
                    <span class="label">
                      堂号：
                    </span>
                    <span class="value">
                      {{ item.tanghao || "无" }}
                    </span>
                  </div>
                  <div class="item-info">
                    <span class="label">
                      卷数：
                    </span>
                    <span class="value">
                      {{ item.juanshu || "无" }}
                    </span>
                  </div>
                  <div class="item-info">
                    <span class="label">
                      谱籍地：
                    </span>
                    <span class="value">
                      {{ item.pujidi || "无" }}
                    </span>
                  </div>
                  <div class="item-info">
                    <span class="label">
                      编撰日期：
                    </span>
                    <span class="value">
                      {{ item.zhuanxieriqi || "无" }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="intro"
              v-if="item.description"
              :class="{ expanded: expanded.has(item.id) }"
              @click.stop="handleIntroClick(item.id)"
            >
              <div class="intro-left">
                <span>简介：</span>
                {{ item.description }}
              </div>
              <span
                :class="
                  expanded.has(item.id)
                    ? 'el-icon-arrow-up'
                    : 'el-icon-arrow-down'
                "
                class="icon"
              />
            </div>
          </div>
        </div>
        <el-pagination
          class="pager"
          background
          layout="prev, pager, next"
          :page-count="maxPage"
          @prev-click="fetchList"
          @next-click="fetchList"
          @current-change="fetchList"
        >
        </el-pagination>
      </div>
    </div>
    <search-dialog
      ref="search-dialog"
      :current-page="page"
      @submit="refreshList"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
// import { List } from "vant";
import { Pagination } from "element-ui";
import materialApis from "@/apis/material";
import SearchDialog from "@/components/web/ExactSearchDialog";

const TYPES = {
  all: "all",
  free: "free",
  charge: "charge"
};

// const mockData = new Array(20).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   timing: "题目",
//   tanghao: "堂号",
//   fayuandi: "发源地",
//   zhuanxieriqi: "编撰日期",
//   price: [0, 1][index % 2],
//   cover: require("@/assets/images/search/cover.png")
// }));

export default {
  name: "Search",
  components: {
    "el-pagination": Pagination,
    SearchDialog
  },
  data() {
    return {
      TYPES,
      activeTab: TYPES.all,
      page: 0,
      maxPage: 0,
      loading: false,
      resultList: [],
      searchParams: {},
      readed: new Set(),
      expanded: new Set()
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  mounted() {
    const storedReaded = JSON.parse(
      localStorage.getItem("readed_pushu") || "[]"
    );
    this.readed = new Set(storedReaded);
  },
  created() {
    this.searchParams = { ...this.$route.query };
    this.fetchList();
  },
  methods: {
    showSearch() {
      this.$refs["search-dialog"].show();
    },

    changeType(type) {
      this.activeTab = type;
      this.refreshList();
    },

    async fetchList(page) {
      console.log("fetch list");
      this.page = page;
      this.loading = true;
      try {
        const {
          data: { material_list, max_page }
        } = await materialApis.searchMaterial({
          ...this.searchParams,
          size: 12,
          isfree:
            this.activeTab === TYPES.all
              ? ""
              : this.activeTab === TYPES.free
              ? 1
              : 2,
          page: this.page
        });
        this.maxPage = max_page;
        this.resultList = material_list;
      } catch (error) {
        // this.finished = true;
        this.error = true;
        console.error(error);
      }
      this.loading = false;
    },

    refreshList(params) {
      this.searchParams = { ...params };
      this.fetchList(1);
    },

    navigateToDetail(id, price) {
      if (!this.userInfo.id && price > 0) {
        this.bus.emit("login");
        return;
      }
      this.readed.add(id);
      localStorage.setItem(
        "readed_pushu",
        JSON.stringify(Array.from(this.readed))
      );
      this.$router.push({
        path: "/web/search/detail",
        query: { id }
      });
    },

    handleIntroClick(id) {
      console.log(id, this.expanded);
      this.expanded.has(id) ? this.expanded.delete(id) : this.expanded.add(id);
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss">
.van-list__loading {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.top {
  height: 282px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(0deg, #fbe2c4 0%, #faf3ea 100%);
  .content {
    align-items: center;
    // background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 190px;
    width: 740px;

    .logo {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 80px;
      position: relative;

      .logo-img {
        height: 80px;
        // margin-right: 11px;
        // width: 83px;
      }
      .logo-text {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 70px;
        // width: 246px;

        .title {
          color: #000000;
          font-size: 36px;
          letter-spacing: 0;
          line-height: 36px;
          position: relative;
          text-decoration: none;
        }

        .english-title {
          -webkit-line-clamp: 2;
          color: #000000;
          font-size: 24px;
          height: 25px;
          letter-spacing: 2.4;
          line-height: 25px;
          margin-top: 13px;
          overflow: hidden;
          position: relative;
          text-decoration: none;
          text-overflow: ellipsis;
          width: 231px;
        }
      }
    }
  }

  .search-area {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    margin-top: 60px;
    position: relative;

    .search-input {
      background-color: #ffffff;
      border-color: #ca0a15;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      height: 50px;
      margin-right: 10px;
      overflow: hidden;
      width: 500px;
      padding: 0 10px;
    }

    .search-btn {
      align-items: center;
      background-color: #ca0a15;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      height: 50px;
      justify-content: center;
      margin-right: 10px;
      overflow: hidden;
      width: 110px;
      cursor: pointer;

      .search-text {
        color: #ffffff;
        font-size: 20px;
        letter-spacing: 0;
        max-width: 98px;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
      }
    }

    .exact-search-btn {
      align-items: center;
      border-color: #ca0a15;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      height: 50px;
      justify-content: center;
      overflow: hidden;
      width: 110px;
      cursor: pointer;

      .exact-search-text {
        color: #ca0a15;
        font-size: 20px;
        letter-spacing: 0;
        max-width: 98px;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
      }
    }
  }
}

.result-content {
  padding: 32px 0;
  box-sizing: border-box;
  // background: linear-gradient(0deg, #fbe2c4 0%, #faf3ea 100%);
  min-height: calc(100vh - 282px - 70px);

  .content-inner {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }

  .search-title {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    height: 30px;
    width: 129px;

    &::before {
      content: "";
      background-color: #ca0a15;
      height: 30px;
      margin-right: 5px;
      overflow: hidden;
      width: 4px;
    }

    .wrapper {
      align-items: center;
      background-color: #ca0a15;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      display: flex;
      flex-direction: row;
      height: 30px;
      justify-content: center;
      overflow: hidden;
      width: 120px;

      .text {
        color: #ffffff;
        font-size: 16px;
        letter-spacing: 0;
        max-width: 108px;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
      }
    }
  }

  .type-tabs {
    border-bottom: 1px solid #ca0a15;
    display: flex;
    margin-top: 15px;

    .tab {
      position: relative;
      width: 87px;
      height: 30px;
      background: #fff;
      border-radius: 4px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 3px;
      margin-right: 10px;
      font-size: 14px;
      color: #666666;
      cursor: pointer;

      &.active {
        opacity: 1;
        color: #ffffff;
        background-color: #ca0a15;

        &::after {
          border-left: 5px solid #ca0a15;
          border-bottom: 15px solid #ca0a15;
        }
      }

      &::after {
        content: "";
        display: block;
        // height: 30px;
        // width: 6px;
        border-right: 5px solid transparent;
        border-top: 15px solid transparent;
        border-left: 5px solid #fff;
        border-bottom: 15px solid #fff;
        position: absolute;
        right: -10px;
        top: 0;
      }
    }
  }

  .result-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% + 25px);

    .result-item {
      border-radius: 4px;
      overflow: hidden;
      align-items: center;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 370px;
      margin-right: 45px;
      margin-top: 15px;
      cursor: pointer;
      position: relative;
      flex: none;

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      .tag-container {
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        height: 30px;
        width: 60px;
        left: 10px;
        overflow: hidden;
        position: absolute;
        top: 10px;
        color: #2fc507;
        font-size: 12px;

        &.charge {
          color: #fdae04;
        }
      }

      .item-main {
        height: 150px;
        display: flex;
        flex-flow: row nowrap;
        position: relative;
      }
    }

    .intro {
      height: 41px;
      border-top: 1px solid #b5b5b5;
      box-sizing: border-box;
      padding: 10px;
      color: #666666;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: normal;
      width: 100%;
      flex: none;
      // display: inline;
      span {
        color: #333333;
        display: inline;
      }

      .intro-left {
        font-size: 12px;
        width: calc(100% - 30px);
        display: inline;
        word-break: break-all;
        line-height: 1.7;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.expanded {
        height: auto;
        align-items: flex-start;

        .intro-left {
          white-space: normal;
          line-height: 1.7;
        }
      }
    }

    .read-tag {
      height: 30px;
      width: 150px;
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
      line-height: 30px;
      position: absolute;
      top: 120px;
      left: 0;
    }

    .item-cover {
      height: 150px;
      width: 150px;
      object-fit: cover;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    }

    .item-content {
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      flex-direction: row;
      height: 150px;
      overflow: hidden;
      position: relative;
      width: 220px;

      .item-content-inner {
        flex: none;
        align-items: flex-start;
        justify-content: space-between;
        border-color: #ca0a15;
        border-radius: 4px;
        border-style: dashed;
        border-width: 1px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 120px;
        width: 200px;
        padding: 14px 10px;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        .item-info {
          font-size: 10px;
          flex: none;
          line-height: 1.3;
          word-break: break-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;

          .label {
            color: #ca0a15;
            line-height: 10px;
            text-decoration: none;
          }

          .value {
            color: #8a7571;
            text-decoration: none;
          }
        }
      }
    }

    .wrap {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 90px;
      margin-left: 9px;
      margin-top: 12px;
      position: relative;
      width: 140px;
    }
  }

  .pager {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}
</style>
