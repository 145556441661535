<template>
  <el-dialog
    :visible.sync="visible"
    :show-close="false"
    width="500px"
    custom-class="modal-container"
  >
    <div slot="title" class="title">
      <span class="info">
        精确搜索
      </span>
      <img
        alt="icon"
        class="icon"
        src="@/assets/images/login-dialog/img_64028_0_0.png"
        @click="visible = false"
      />
    </div>
    <div class="form">
      <el-input
        class="form-input"
        v-model="searchForm.sn"
        placeholder="请输入编号"
      />
      <el-input
        class="form-input"
        v-model="searchForm.title"
        placeholder="请输入题名"
      />
      <el-input
        class="form-input"
        v-model="searchForm.pujidi"
        placeholder="请输入谱籍地"
      />
      <el-input
        class="form-input"
        v-model="searchForm.timing"
        placeholder="请输入支系"
      />
      <el-input
        class="form-input"
        v-model="searchForm.xiuzhuanzhe"
        placeholder="请输入修撰者"
      />
      <el-input
        class="form-input"
        v-model="searchForm.banbennian"
        placeholder="请输入版本年"
      />
      <el-input
        class="form-input"
        v-model="searchForm.tanghao"
        placeholder="请输入堂号"
      />
      <el-input
        class="form-input"
        v-model="searchForm.zibei"
        placeholder="请输入字辈"
      />
      <el-input
        class="form-input"
        v-model="searchForm.fayuandi"
        placeholder="请输入发源地"
      />
      <el-input
        class="form-input"
        v-model="searchForm.description"
        placeholder="请输入简介"
      />
    </div>
    <div class="submit-area">
      <el-button class="search-btn" @click="submit">搜索</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import { mapActions } from "vuex";
import { Dialog, Button, Input } from "element-ui";
// import apis from "@/apis/index";

const initForm = () => ({
  sn: "",
  pujidi: "",
  timing: "",
  xiuzhuanzhe: "",
  banbennian: "",
  tanghao: "",
  zibei: "",
  fayuandi: "",
  description: "",
  title: ""
});

export default {
  name: "SearchDialog",
  components: {
    "el-dialog": Dialog,
    "el-button": Button,
    "el-input": Input
  },
  data() {
    return {
      visible: false,
      searchForm: {}
    };
  },
  mounted() {
    this.searchForm = initForm();
  },
  methods: {
    show() {
      // this.searchForm = initForm();
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },

    async submit() {
      this.visible = false;
      this.$emit("submit", this.searchForm);
    }
  }
};
</script>

<style lang="scss">
.form-input {
  .el-input__inner {
    color: #ca0a15;
    font-size: 14px;
    border: 1px solid #f0f0f0;
  }
}
</style>

<style lang="scss">
.modal-container {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  align-items: center;
  background-color: #fbe1c3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 21px;
  padding-right: 20px;
  position: relative;
  width: 500px;

  .info {
    color: #ca0a15;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
    font-weight: bold;
  }
  .icon {
    height: 10px;
    width: 10px;
    cursor: pointer;
  }
}
.form {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 20px 0 20px;

  .form-input {
    width: 225px;
    margin-bottom: 20px;

    &:nth-of-type(2n + 1) {
      margin-right: 10px;
    }
  }
}

.submit-area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 30px;

  .search-btn {
    width: 200px;
    background-color: #ca0a15;
    color: #fff;
  }
}
</style>
